.news{
    text-align: center;
    margin: 2em 0 4em 0;
    padding: 2em 0 0em 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    @media(min-width:1250px){
     height: 50vh;       
    }
    h1{
        font-weight: 700;
    }
    p{
        margin: 1em 0 2em 0;
        text-align: center;
        font-size: 1.1rem;
    }
}
.newsletter{
    background-color: rgba(106, 13, 173,0.1);
    text-align: center;
    border: 0.5px solid rgba(106, 13, 173,0.1);
   height: 5.1em;
   border-radius: 10px;
   display: flex;
   justify-content: space-between;
   flex-wrap: nowrap;
   gap: 0;
    @media(max-width:768px){
        width: 90vw;
        padding: 0;
    }
    @media(max-width:350px){
        // width: 13rem;

    }
}

.provider {
   
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0em 0 10em 0;

}

.happyusers {
   
color:  #ff631c;
font-family: "Great Vibes", "Fantasy", cursive; 
font-size: 2rem;  
font-weight: normal; 
text-align: center; 
text-shadow: 0 1px 1px #fff;
  

    

}


.input{
    border: none;
    width: 30vw;
    padding: 1.5em 2em;
    background-color: rgba(106, 13, 173,0.05);
    //margin-left:1em;
    @media(max-width:768px){
        height: inherit;
        margin-left: 0;
        padding-left: 5px;
        width: 70vw;
        
    }
    @media(max-width:420px){
        // width: 6rem;
        font-size: 0.7rem;

    }
    &:hover{
        border: none;
    }
    &:active{
        border: none;
    }
    &:focus{
        border: none;
        outline: none;
    }
}
.newsbtn{
    background-color: #6a0dad;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    width: 10em;
    height: 4em;
    margin:0.5em 0.5em;
    transition: 0.3s ease all;
    @media(max-width:768px){
       width: 8em;
        padding: 0;
    }
    // @media(max-width:350px){
    //     width: 5rem;
    //     font-size: 0.9rem;

    // }
    @media(min-width:769px){
    font-size: 1rem;
    }
        @media(min-width:1850px){
            
        }
      
    
    &:hover{
        border: 0.5px solid transparent;
        border-color: #ff631c;;
        background-color: #ff631c;
        color: #ffffff;
        
    }
   
}