.footer-container{
    background-color: rgba(106, 13, 173,0.05);
    text-align: center;
    margin: 0;
    border-radius: 40px;
}.footer{
    display: flex;
    flex-direction: column;
    height: auto;
    // background-color: rgba(249, 250, 252, 0.2);
   
    padding: 10vh 3em 0 2em;
@media(min-width:769px){
        flex-direction: row;
        height: auto;
        align-items:center;
        align-content: center;
        padding-top: 1vh;
}
    @media(min-width:1850px){
        }
}


.footerleft{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    text-align: center;
    flex-direction: column;

    @media(min-width:769px){
        padding: 2em 0 0 0;
        width: 50%;
        align-items: flex-start;
    }
        @media(min-width:1150px){
            // padding: 0 3em 0 3em;
            // width: 30%;
        }
      
    }
.footerlogo{
    display: block;
    text-decoration: none;
    color: #333;
    // padding-bottom: 0.5em;
    transition: 0.3s ease;
    cursor: pointer;
    text-transform: uppercase;
}

.footerbtn {

    @media(min-width:769px){
        text-align: left;
    }
button{
    // padding: 0.7em;
    color: #6a0dad;
    background-color: white;
    box-shadow: 3px 3px #e9eaec;
    height: 5em;
    border: none;
    height: auto;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    transition: 0.3s ease;
    margin:0 15px 2em 0;
    @media(min-width:769px){
    }
        @media(min-width:1850px){
            
        }
    }
}
.footerlink{
    text-decoration: none;
}
h2{
    margin: 0;
    padding: 0;
}
p{
    width: 70%;
    line-height: 1.7em;
    font-size: 0.9rem;
    @media(max-width:768px){
        margin-left: auto;
        margin-right: auto;
    }
    @media(min-width:769px){
    text-align: left;
        line-height: 2em;
        margin-bottom: 2em;
    }
        @media(min-width:1850px){
            
        }
      
    
}


.footerinfotext  {

    font-size: 1.2rem;
    text-align: left;
    word-spacing: -1px;
    @media(max-width:768px){
        font-size: 1.2rem;

    }

}

.footerright{
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    width: 100%;
    padding-top: 4em;
    text-align: left;
    display: flex;
    
  
    @media(min-width:769px){
        padding-top: 0;
        width: 80%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        align-content: space-between;
        text-align: left;
    }
        @media(min-width:1250px){
            padding: 0;
            margin: 0;
        }
}
.linkTitle{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
    @media(min-width:769px){
    margin-bottom:3em;
    }
        @media(min-width:1850px){
            
        }

        @media only screen and (max-width: 600px) {
         
              
              margin-left: 0;
            }
      
    
}
.footerlink{
    display: block;
    text-decoration: none;
    color: #333;
    padding-bottom: 1em;
    transition: 0.3s ease;
    cursor: pointer;
    text-transform: none;
    font-size: 1.1rem;
    @media(min-width:769px){
    height: 3em;
    font-size: 1.1rem;
    }
        @media(min-width:1850px){
            
        }
      
    
    &:hover{
        color:#ff631c;
    }
}
.copy{
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 2rem;
    @media(max-width:768px){
        margin: 1.5rem 0 0 0;
        font-size: 1rem;
    }
}