.Aboutcard{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2em 2em;
    position: relative;
    padding: 0 5em 0 5em;
    @media(max-width:768px){
         gap: 2em;
    }
    @media(max-width:1120px){
        padding: 0;
        gap: 2em 0;
    }
    @media(min-width:1200px){
        justify-content: space-around;
        gap: 2em 0;
        padding: 0;
    }
    
    // .bg{
    //     background-color: #d81f0a;
    //     height: 100%;
    //     width: 100%;
    //     border-radius: 30px;
        
    // }
    

}
.about-container{
    height: 100%;
    width: 100%;

    @media(max-width:768px){
        width: 100%;
        height: 100%;
    }
    @media(min-width:1250px){
     width: 100%;   
     height: 100%;    
    }
  
    .card-img{
       
        margin-top: -6em;

        @media(max-width:768px){
            margin-top: -14em;
        }
        @media(min-width:1250px){
            
        }


        img{
            
        margin-top: -6em;

        @media(max-width:768px){
            margin-top: -14em;
        }
        @media(min-width:1250px){
            
        }
        }
}
}
.about-text{
    display: flex;
    flex-direction: column;
    padding: 3em;
    align-items: center;
    justify-content: center;
    text-align: justify;
    word-spacing: -1px;
    @media(max-width:768px){
        height: 80%;
        padding: 1rem;
    }
    @media(min-width:1250px){
     height: auto;       
    }
    h2{
        font-weight: 700;
        margin-top: 4em;
        font-size: 2rem;
        @media(max-width:768px){
            margin: 4rem 0 1.5rem 0;
            padding: 1.5rem 0 1.5rem 0;
            
            
        }
    }
    p{
        margin-top: 3em;
        text-align: justify;
        color: #000000;
        font-size: 1.2rem;
        font-weight: 400;

        @media(max-width:768px){
        margin: 2rem;
        text-align: justify;
        color: #000000;
        font-size: 1.1rem;
        font-weight: 400;
        }
    }
}
