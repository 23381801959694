body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 18px;
  /* font-family: 'Overpass', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;

}

