.Nav{
    height: 4em;
    padding: 0.7em 1.5rem 0 5em;
    transition: 0.3s ease;
    position: fixed;
    top: 0;
    width: 100%;
    // overflow: hidden;
    background-color: rgb(106, 13, 173);
    z-index: 1000;
   &.active{
    background-color: rgb(106, 13, 173);
    // z-index:1000;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.2);
    .arrow{
        filter: invert(0.2);
    }
    .navContainer #logo,.navlink,.toggle{
        color: white;
        font-size: 1.1rem;
        &:hover{
            color: #ff631c;
        }
    }
   }
    @media (max-width:768px){
        padding: 0 3rem;

    }
}
.navContainer{
    width: 90%;
    display: flex;
    justify-content: space-between;
    @media(max-width:768px) {
        flex-direction: column;
        justify-content: center;
    }
}


.logoham{
    display: flex;
    width: 30%;
    @media(max-width:768px){
        width: 115%;
        height: 15vh;
        justify-content: space-between;
        align-items: baseline;
        margin: 0;
    }
    @media(min-width: 769px){
        width: 15%;
    }
    @media(min-width: 1150px){
        width: 25%;
    }
}
.navContainer #logo{
    color: white;
    text-decoration: none;
    font-size: 1.8rem;
    margin: -20px;
    @media(min-width:769px){
        font-size: 2rem;
    }
}
.toggle{
    display: none;
    cursor: pointer;
    color: white;
    transition: 0.3s ease;
    font-size: 2rem;
    &:hover{
        font-size: 2.2rem;
    }
    @media (max-width: 768px){
        display: block;
    }
}

nav{
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(2px);
  
    transition: 0.3s ease transform;
    @media (max-width:768px){
        flex-direction: column;
        transform: translate(0);
        display: none;
        height: 50vh;
        align-items: flex-start;
        justify-content:flex-start;
        &.menuOpen{
            transform: translate(100);
            display: flex;
        }
    }
    ul{
        display: flex;
        width: 100%;
        // flex-wrap: wrap;
        padding: 0 4rem 0 4rem;
        list-style: none;
        justify-content: space-between;
       
        @media(max-width:768px){
            flex-direction: column;
            width: 100%;
            padding: 0;
            margin: 0;
            height: 70%;
            // align-items: center;     
     }
    }
    li{
        list-style: none;
        position: relative;
    }
    .navlink{
        color: white;
        text-decoration: none;
        font-size: 1.1rem;
        transition: 0.3s ease;
        @media(min-width:769px){
            font-size: 1.1rem;
        }
        &:hover{
            color: #ff631c;
        }
    }

    .navlinksub{
        color: rgb(106, 13, 173);
        text-decoration: none;
        font-size: 1rem;
        transition: 0.3s ease;
        @media(min-width:769px){
            font-size: 1rem;
        }
        &:hover{
            color: #ff631c;
        }
    }
    
}

.arrow{
    margin-left: 4px;
    color: white;
    width:15px;
    border-radius: 50%;
}




// submenu styling (first mobile design)
.sub-menu{
    display: block;
    position: absolute;
    top: 35px;
    background-color: white;
    height: auto;
    width: 60vw;
    display: flex;
    overflow: initial;
    flex-direction: column;
    box-shadow: rgba(0,0,0,0.45) 20px 25px 20px -20px;
    border-radius: 15px;
    padding: 1em 0 1em 1em;
    align-content: space-between;
    z-index: 1;
    flex-wrap: wrap;
    @media(min-width:769px){
        width: 33vw;
    }
   
    .grid{
    @media(min-width:769px){
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 2vw;
    }
}
    h3{
        font-weight: 700;
        margin-bottom: 1em;
        font-size: 1rem;
    }
    li{
        list-style: none;
        margin-bottom: 1em;
        transition: 0.3s ease;
        a{
            color:rgb(106, 13, 173);
        }
        
    }
}





// submenu styling (first mobile design)
.sub-menu-company{
    display: block;
    position: absolute;
    top: 35px;
    background-color: white;
    height: auto;
    width: 60vw;
    display: flex;
    overflow: initial;
    flex-direction: column;
    box-shadow: rgba(0,0,0,0.45) 20px 25px 20px -20px;
    border-radius: 15px;
    padding: 1em 0 1em 1em;
    align-content: space-between;
    z-index: 1;
    flex-wrap: wrap;
    @media(min-width:769px){
        width: 15vw;
    }
   
    .grid{
    @media(min-width:769px){
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 2vw;
    }
}
    h3{
        font-weight: 700;
        margin-bottom: 1em;
        font-size: 1rem;
    }
    li{
        list-style: none;
        margin-bottom: 1em;
        transition: 0.3s ease;
        a{
            color:rgb(106, 13, 173);
        }
        
    }
}
