.Card{
    display: flex;
    margin: 3em;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2em 2em;
    position: relative;
    padding: 0 5em 0 5em;
    @media(max-width:768px){
         gap: 2em;
    }
    @media(max-width:1120px){
        padding: 0;
        gap: 2em 0;
    }
    @media(min-width:1200px){
        justify-content: space-around;
        gap: 2em 0;
        padding: 0;
    }
    .one{
        background-color: #fff5dc;
    }
    .two{
        background-color: #fff6f5;
    }
    .three{
        background-color: #ddf0ff;
    }
    .four{
        background-color: #fff6f5;
    }

    .five{
        background-color: #eafdff;
    }

    .six{
        background-color: #d2ffef;
    }

    .seven{
        background-color: #fff5dc;
    }

    .eight{
        background-color: #ffe9ff;
    }

    .nine{
        background-color: #eafdff;
    }

}
.card-container{
    display: flex;
    // height: 80vh;
    // height: 100%;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    width: 60rem;
    flex-direction: column;
    row-gap:1em;
    border-radius: 30px;
    // flex-wrap: wrap;
    @media(max-width:768px){
        width: 100%;
        //height: auto;
        // height: 100%;
    }
    @media(min-width:1250px){
     width: 30rem;  
     //height: auto; 
    //  height: 100%;    
    }
  
    .card-img{
        img{
            max-width: 100%;
            //max-height: 100%;
            max-height: 100%;
            
        }
}
}

.card-text{
    height: 50%;
    display: flex;
    flex-direction: column;
    padding: 3em;
    align-items: center;
    //justify-content: center;
    text-align: left;
    word-spacing: -1px;
    color: #000000;
    font-size: 1.2rem;
    font-weight: 400;
    @media(max-width:768px){
        height: 80%;
        padding: 1rem;
        color: #000000;
        font-size: 1.1rem;
        font-weight: 400;
    }
    @media(min-width:1250px){
     height: auto;       
    }
    h2{
        font-weight: 700;
        margin-bottom: 1.5em;
        font-size: 1.5rem;
        @media(max-width:768px){
            margin: 1.5rem 0 1.5rem 0;
            padding: 1.5rem 0 1.5rem 0;
            font-size: 1.3rem;
            
            
        }
    }
  
}
.card-btn{
    border: none;
    width: 9em;
    height: 3em;
    color: white;
    background-color: #ff631c;
    transition: 0.3 ease;
    border-radius: 5px;
    margin-top: 70px;
    cursor: pointer;
    @media(min-width:769px){
        font-size: 1.2rem;
    }
        @media(min-width:1850px){
            
        }
      
    
    &:hover{
        background: #6a0dad;
        border: 0.5px white outset;
        color: white;
    }
}