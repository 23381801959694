@mixin breakpoint($point){
    @if $point == md{
        //768px
        @media(min-width: 48em){
            @content;
        }
    }
}
.dflex{
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100%;
    
}
@import './home';
@import './nav';
@import './banner';
@import './card';
@import './centre';
@import './newsletter';
@import './footer';
@import './aboutcard';
@import './about'
