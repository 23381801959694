.Centre{
    text-align: center;
    .title{
        font-weight: 700;
        margin: 2em 0 2em 0;
        font-size: 3rem;
    }
}
.centre-container{
    display:flex;
    justify-content: space-around;
    background-color: #f0ebfd;
    position: relative;
    height: 80vh;
    border-radius: 30px;
    margin: 0 5em 0 5em;
    align-items: center;
    padding: 0 5em 0 5em;
    overflow: hidden;
    @media(max-width:768px){
        width: 90%;
        margin: 0 1em 0 1em;
        
        
    }
    @media(max-width:1200px){
        flex-direction: column-reverse;
        height: 130vh;
        align-content:flex-end;
        padding: 0;
    }
    h1{
        font-weight: 700;
        margin:0 2em 1em 2em;
        line-height: 1.5em;
        font-size: 3rem;
        text-align: left;
        @media(max-width:768px){
            margin-top: 3em;
        }
        @media(max-width:1300px){
            text-align: center;
            font-size: 2rem;
        }
    }
}
.centre-container::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	border-style: solid;
	border-color: transparent;
	border-width: 10vh 60vw;
	border-top-color: white;
	border-right-color: white;
}


.centre-btn {

    @media(min-width:769px){
        text-align: left;
    }
button{
    // padding: 0.7em;
    color: #6a0dad;
    background-color: white;
    box-shadow: 3px 3px #e9eaec;
    height: 5em;
    border: none;
    height: auto;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    transition: 0.3s ease;
    margin:0 15px 2em 0;
    @media(min-width:769px){
    }
        @media(min-width:1850px){
            
        }
    }
}



.phone-img{
    height: inherit;
    z-index: 100;
    display: flex;
    margin-right: 2em;
    @media(max-width:1200px){
        display: block;
        max-height: 40%;
        width: 100%;
    }
}
